const getCharacteristicFromFull = (characteristics = [], slug) => {
  let full = [];
  characteristics.map((item) => {
    full.push(...(item.characteristics || []))
  });

  let characteristic = Boolean(Array.isArray(slug)) ? full.filter((t) => slug.includes(t.slug)) : full.find((t) => t.slug === slug);

  if (slug === 'collection_color') {
    characteristic = sortValuesBigToSmall(characteristic);
  }

  return characteristic;
}
const getCharacteristicFromShort = (characteristics) => {};

const sortValuesBigToSmall = (characteristic) => {
  let values = (characteristic?.values || []);
  values = values.sort((a, b) => {
    if (a?.sort < b?.sort) {
      return 1
    }
    if (a?.sort > b?.sort) {
      return -1
    }
    return 0
  });
  return {
    ...characteristic,
    values
  }
}

export {
  getCharacteristicFromFull,
  getCharacteristicFromShort,

  sortValuesBigToSmall
}
